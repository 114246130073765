import * as React from "react";
import {RouteComponentProps} from "@reach/router";

const TurvallinenPerhe = (props: RouteComponentProps) => {
  return (
    <div style={{margin: "50px"}}>
      <h1>
        Turvallinen perhe -sovelluksen tietoturvaseloste
      </h1>

      <p>
        Turvallinen perhe on Hyvä Mieli Ry:n luoma sovellus väkivaltatyön avuksi.
      </p>

      <p>
        Sovellus tai sovelluksen käyttämät kolmannen osapuolen palvelut eivät kerää henkilötietojan käyttäjistä
        eikä rekisteriä muodostu. Kaikki tiedot, mitä käyttäjä syöttää sovellukseen tallentuu laitteen tietoihin
        ja poistetaan sovelluksen poiston yhteydessä.
      </p>
      <p>Lisätietoa:</p>
      <p>hei@ludoful.fi</p>
      <p>+35850 404 7470</p>
    </div>
  );
}

export default TurvallinenPerhe;
